<template>
  <div class="van-link">
    <div class="link-button">
      <van-field v-model="rechargeAmount" type="number" clearable placeholder="请输入充值金额"/>
      <van-button class="van-button" color="linear-gradient(to right,  #aaa, #989898)" @click="openLogin">登录
      </van-button>
      <van-button class="van-button" color="linear-gradient(to right,  #aaa, #989898)" @click="Recharge">充值</van-button>
      <van-button class="van-button" color="linear-gradient(to right,  #aaa, #989898)" @click="uploadRole">上传角色
      </van-button>
      <van-button class="van-button" color="linear-gradient(to right,  #aaa, #989898)" @click="onLogout">注销</van-button>
    </div>
  </div>
</template>
<script>
import '../../../public/youxiyou'
import {Button, Field, Popup} from 'vant'
import {getTime} from "@/utils/make";
import {ref} from "vue";

export default {
  name: "Demo",
  components: {
    [Field.name]: Field,
    [Button.name]: Button,
    [Popup.name]: Popup,
  },
  setup() {
    const uid = ref(0)
    const token = ref("")
    const rechargeAmount = ref(0)

    window.YXY_SDK.login((data) => {
      console.log("登录",uid.value,token.value,'data',data)
      if(!uid.value || !token.value){
        uid.value = data.user_id
        token.value = data.token
      }
    })

    window.YXY_SDK.setLogoutCallback(() => {
      uid.value = 0
      token.value = ''
      console.log("注销回调",uid.value,token.value,)
    })

    const openLogin = () => {
      window.YXY_SDK.login((data) => {
        console.log("手动拉起登录",uid.value,token.value,'data',data)
        if(!uid.value || !token.value){
          uid.value = data.user_id
          token.value = data.token
        }
      })
    }

    const Recharge = () => {
      const payInfo = {
        uid: uid.value,
        amount: (rechargeAmount.value && rechargeAmount.value != 0 ? rechargeAmount.value : 0.1),
        extend: getTime()
      }
      console.log(payInfo)
      window.YXY_SDK.pay(payInfo)
    }
    const onLogout = () => {
      window.YXY_SDK.logout()
    };
    const uploadRole = () => {
      const roleInfo = {
        uid: uid.value,
        server_id: 0,
        server_name: "测试服务器",
        role_id: 5521,
        role_name: "狂奔小蜗牛",
        role_level: "999",
        combat_number: "9999",
      }
      window.YXY_SDK.uploadRole(roleInfo)
    }
    return {
      uid,
      token,
      openLogin,
      Recharge,
      onLogout,
      uploadRole,
      rechargeAmount
    };
  }
}
</script>

<style scoped>
.van-link {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.van-button {
  border-radius: 20px;
  margin: 50px 22px;
  height: 150px;
  max-width: 146px;
  width:50%;
}
</style>
