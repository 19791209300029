(function (w) {
    const youxiyou = w.YXY_SDK || {};
    youxiyou.loginCallback = null
    youxiyou.loginoutCallback = null
    w.addEventListener('message', function (e) {
        if (!e.data) return;
        switch (e.data.action) {
            case "callback:login":
                youxiyou.loginCallback && youxiyou.loginCallback(e.data)
                break;
            case "callback:logout":
                youxiyou.loginoutCallback && youxiyou.loginoutCallback()
                break;
        }
    })

    // 合并参数
    youxiyou.extend = function (target, options) {
        if (target === undefined || target === null) {
            return options;
        } else {
            if (options) {
                for (const name in options) {
                    target[name] = options[name];
                }
            }
            return target;
        }
    }
    // 发送信息
    youxiyou.sendMessage = function (data) {
        window.top.postMessage(data, '*')
    }
    // 支付
    youxiyou.pay = function (data) {
        const defaults = {
            action: "pay",
            uid: 0,
            extend: '',
            amount: 1,
            game_player_id: '',
            game_player_name: '',
            role_level: '',
            server_id: '',
            server_name: '',
            props_name: '',
        };
        data = youxiyou.extend(defaults, data)
        youxiyou.sendMessage(data)
    }
    youxiyou.setLogoutCallback = function (logoutCallback) {
        youxiyou.loginoutCallback = logoutCallback
    }
    // 登陆
    youxiyou.login = function (callback) {
        youxiyou.loginCallback = callback
        const data = {
            action: 'login',
        }
        youxiyou.sendMessage(data)
    }
    // 退出登陆
    youxiyou.logout = function () {
        const data = {
            action: 'logout'
        }
        youxiyou.sendMessage(data)
    }
    // 上传角色信息
    youxiyou.uploadRole = function (data) {
        const defaults = {
            action: 'uploadRole',
            uid: 0,
            server_id: 0,
            server_name: "",
            role_id: "",
            role_name: "",
            role_level: "",
            combat_number: "",
        }
        data = youxiyou.extend(defaults, data)
        youxiyou.sendMessage(data)
    }
    w.YXY_SDK = youxiyou;
})(window)
